<template>
  <v-app style="background: #e8f0fe">
    <v-main>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",

  data: () => ({}),
  methods: {
    ...mapActions({
      init: "init",
    }),
  },
  mounted() {
    this.$vuetify.theme.themes.light.primary = "#131E30";
    this.$vuetify.theme.themes.dark.primary = "#131E30";
    this.$vuetify.theme.themes.light.secondary = "#C3C3C3";
    this.$vuetify.theme.themes.dark.primary = "#C3C3C3";
    this.init();
  },
};
</script>
<style lang="scss">
$body-font-family: "Montserrat";
$title-font: "Montserrat";

.v-application {
  height: 100%;
  width: 100%;

  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

.v-application a {
  color: #fff !important;
}

html body {
  height: 100%;
  width: 100%;
  touch-action: none;
  overflow: hidden;
  user-select: none;
  background: #e8f0fe !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #131e30;
  border-radius: 2.5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
