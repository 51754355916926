<template>
  <apexchart
    v-if="!isLoading"
    height="900px"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { smoothOut } from "../../utils";
export default {
  name: "CapaVsProfChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    /**
     * Données d'entré avant traitement pour le graphique.
     */
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    /**
     * Données du graphique.
     */
    series: [
      {
        name: "E.L.S",
        data: [],
      },
      {
        name: "E.L.U",
        data: [],
      },
    ],

    /**
     * Options du graphique ApexChart
     * https://apexcharts.com/docs/chart-types/line-chart/
     */
    chartOptions: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        zoom: {
          enabled: false,
        },
        type: "line",
        stacked: false,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 1,
        dashArray: 0,
      },
      markers: {
        size: 0,
        colors: ["transparent"],
        strokeWidth: 0,
      },
      title: {
        text: "",
        align: "left",
      },
      yaxis: {
        lines: {
          show: true,
        },
        tickAmount: 12,
        max: 0,
        title: {
          text: "Profondeur en cm",
        },
        tooltip: {
          enabled: true,
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
      },
      xaxis: {
        marker: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          marker: {
            show: false,
          },
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
        lines: {
          show: true,
        },
        tickAmount: 8,
        min: 0,
        title: {
          text: "Couple en lbs.pieds",
        },
      },
    },
  }),
  mounted() {
    const scope = this;
    this.isLoading = true;

    const seriesData = [...this.items];

    seriesData.sort((a, b) => {
      return b.deltaZ - a.deltaZ;
    });

    let capa_max = -9999;
    const coeff1 = 5.3273;
    const coeff2 = -4026.6;
    let capa_els = 0;
    let capa_elu = 0;

    const testArr1 = [];
    const testArr2 = [];
    const testDeltaZ = [];

    const nombre_points = seriesData.length;
    this.series[0].data.push([0, 0]);
    this.series[1].data.push([0, 0]);
    for (let index = 0; index < nombre_points; index++) {
      if (index > 0 && index < nombre_points - 1) {
        seriesData[index].capacite_portante = Math.round(
          (parseInt(seriesData[index - 1].Torque) +
            parseInt(seriesData[index + 1].Torque)) /
            2
        );
      } else {
        seriesData[index].capacite_portante = parseInt(
          seriesData[index].Torque
        );
      }

      /**
       * Code PHP fournit par Cédric.
       */
      let capa = 0;
      const couple_pt = seriesData[index].capacite_portante;
      if (couple_pt < 6048)
        // en lb.ft
        capa = 9 * couple_pt;
      else if (couple_pt >= 6048 && couple_pt < 9441) {
        capa = (-0.0006 * couple_pt + 12.565) * couple_pt;
      } else {
        capa = 7 * couple_pt;
      }

      capa_els = capa / 2;
      capa_elu = capa * 0.7;
      if (capa_els < 0) capa_els = 0;
      if (capa_elu < 0) capa_elu = 0;
      capa_max = Math.max(capa_max, capa_elu);

      testArr1.push(parseInt(capa_els));
      testArr2.push(parseInt(capa_elu));
      testDeltaZ.push(seriesData[index].deltaZ);
    }

    let testArraySmooth = smoothOut(testArr1, 0.9);
    let testArraySmooth2 = smoothOut(testArr2, 0.9);

    testArraySmooth.forEach((element, index) => {
      this.series[0].data.push([element, testDeltaZ[index]]);
    });
    testArraySmooth2.forEach((element, index) => {
      this.series[1].data.push([element, testDeltaZ[index]]);
    });

    this.isLoading = false;
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
::v-deep .apexcharts-tooltip,
.apexcharts-xcrosshairs {
  display: none !important;
}
</style>
