import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import MapView from "../views/Map.vue";
import ReportView from "../views/Report.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    meta: { transition: "slide-left" },
    component: Login,
  },

  {
    path: "/map",
    name: "Map",
    component: MapView,
    meta: { transition: "slide-left" },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report",
    name: "Report",
    meta: { transition: "slide-left" },
    component: ReportView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/debugger",
    name: "Debugger",
    meta: { transition: "slide-left" },
    component: () => import("../views/GoogleDebug.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/rawTable",
    name: "rawTable",
    meta: { transition: "slide-left" },
    component: () => import("../views/Raw.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

/**
 * Route guard.
 */
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //2023-03-29: JCU: hack to redirect to the main page instead of login if the user is already logged in
  if (!requiresAuth && to.name.toLowerCase() == 'login' && localStorage.getItem("accessToken")) {
    next();
    return setTimeout(() => router.replace({ name: "Map" }));
  }
  if (requiresAuth) {
    if (router.app.$store && router.app.$store.getters.isLoggedIn) {
      next();
    } else {
      if (localStorage.getItem("accessToken")) {
        next();
      }
      next("/");
    }
  } else {
    next();
  }
});

export default router;
