import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-stage',{ref:"stage",staticStyle:{"margin-left":"calc(50% - 335px)"},attrs:{"config":{ width: 670, height: 670 }}},[_c('v-layer',{ref:"layer"},[_vm._l((_vm.localReportPoints),function(item,index){return _c('v-text',{key:'textPoint' + index,attrs:{"config":{
          x: _vm.localReportPoints.length > 1 ? item.x + 5 : 285 + 5,
          y: _vm.localReportPoints.length > 1 ? item.y + -30 : 285 + -30,
          text: item.text + item.reportId ? item.text + item.reportId : '',
        }}})}),_vm._l((_vm.localMidPoints),function(item,index){return _c('v-text',{key:'text' + index,attrs:{"config":{
          x: item.x + 5,
          y: item.y + -20,
          text: item.distanceFromLast.toFixed(2) + 'm',
        }},on:{"click":function($event){return _vm.clickPile(item)}}})}),_c('v-line',{attrs:{"config":{
          points: _vm.localLinePoints,
          stroke: 'black',
        }}}),_vm._l((_vm.localReportPoints),function(item,index){return _c('v-circle',{key:'circle' + index,attrs:{"config":{
          x: _vm.localReportPoints.length > 1 ? item.x : 285 + 50,
          y: _vm.localReportPoints.length > 1 ? item.y : 285 + 50,
          radius: 10,
          fill: 'red',
        }}})})],2)],1),_c(VSimpleTable,{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Pieux No")]),_c('th',{staticClass:"text-left"},[_vm._v("Modèle")]),_c('th',{staticClass:"text-left"},[_vm._v("Prof finale")]),_c('th',{staticClass:"text-left"},[_vm._v("Couple final")]),_c('th',{staticClass:"text-left"},[_vm._v("Capacité")])])]),_c('tbody',_vm._l((_vm.localReportPoints.filter((i) => i.reportId !== '')),function(item){return _c('tr',{key:item.uuid},[_c('td',[_vm._v(_vm._s(item.text + item.reportId))]),_c('td'),_c('td',{directives:[{name:"show",rawName:"v-show",value:(item.profImperial),expression:"item.profImperial"}]},[_vm._v(" "+_vm._s(item.prof + `m (${item.profImperial})`)+" ")]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(item.lastTorq),expression:"item.lastTorq"}]},[_vm._v(_vm._s(item.lastTorq + ` lb.ft `))]),_c('td')])}),0)]},proxy:true}])}),_c(VMenu,{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c(VTextField,{attrs:{"autofocus":"","type":"number","filled":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;(_vm.showMenu = false), _vm.itemClicked($event, { reportId: _vm.id })}},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }