<template>
  <apexchart
    v-if="!isLoading"
    height="600px"
    :options="chartOptions"
    :series="series"
  >
  </apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "PileChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    /**
     * Données d'affichage du graphique.
     */
    series: [
      {
        name: "Angle X",
        data: [],
      },
      {
        name: "Angle Y",
        data: [],
      },
    ],
    /**
     * Les options du graphiques ApexChart
     * https://apexcharts.com/docs/chart-types/line-chart/
     */
    chartOptions: {
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      stroke: {
        show: true,
        curve: "straight",
        lineCap: "butt",
        colors: undefined,
        width: 1,
        dashArray: 0,
      },
      chart: {
        zoom: {
          enabled: false,
        },
        type: "line",
        stacked: false,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: "Angle",
        align: "left",
      },
      yaxis: {
        title: {
          text: "Angles",
        },
      },
      xaxis: {
        tickAmount: 20,
        min: 0,
        max: 215,
        title: {
          text: "Profondeur en cm",
        },
      },
      tooltip: {
        shared: false,
      },
    },
  }),
  mounted() {
    this.isLoading = true;

    const seriesData = [...this.items];

    seriesData.sort((a, b) => {
      return a.deltaZ - b.deltaZ;
    });

    this.series[0].data = seriesData.map((item) => {
      return [item.deltaZ, item.Angle_X];
    });
    this.series[1].data = seriesData.map((item) => {
      return [item.deltaZ, item.Angle_Y];
    });

    this.isLoading = false;
  },
  computed: {},
  methods: {},
};
</script>
