<template>
  <div style="width: 100%; height: 100%">
    <l-map
      style="
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 41%;
        z-index: -1;
      "
      ref="myMap"
      @ready="mapReady"
      :zoom="zoom"
      :max-zoom="20"
      :center="center"
    />
    <div style="height: 43%">
      <GmapMap
        id="map"
        ref="gmap"
        :zoom="zoom"
        :center="centerGmap"
        :options="{ mapTypeId: 'satellite', disableDefaultUI: true }"
      >
      </GmapMap>
    </div>

    <div style="height: 57%">
      <CreateReport
        ref="CreateReportComponent"
        @onBack="onCreateBack"
        @onReportGenerated="drawReportPiles"
        @onAddressSelected="onAddressSelected"
      />
    </div>

    <RoundButton @onClick="logOut()" icon="mdi-arrow-u-left-top" left="15px" />

    <RoundButton left="85px" @onClick="resetMap()" />

    <v-snackbar color="#0d1f30" v-model="isLoading" style="z-index: 9999">
      <v-progress-circular
        :size="15"
        indeterminate
        v-if="isLoading"
      ></v-progress-circular>
      &nbsp;&nbsp;&nbsp;
      {{ "Please wait..." }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import L from "leaflet";
import { LMap, LPopup, LMarker, LTileLayer } from "vue2-leaflet";
import VueApexCharts from "vue-apexcharts";
import RoundButton from "@/components/buttons/RoundButton";

const R = 6378137;
const PI_360 = Math.PI / 360;

const pageStates = {
  LIST: "LIST",
  CREATE_REPORT: "CREATE_REPORT",
};

import CreateReport from "./CreateReport";
import { getDistance } from "@/utils";

export default {
  name: "Map",
  components: {
    apexchart: VueApexCharts,
    LMap,
    LPopup,
    LMarker,
    LTileLayer,
    RoundButton,
    CreateReport,
  },
  data: () => ({
    currentState: "LIST",
    pageStates: pageStates,
    map: undefined,
    gmap: undefined,
    isLoading: true,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 7,
    center: [46.05030879999986, -71.38202359999987],
    centerGmap: { lat: 46.05030879999986, lng: -71.38202359999987 },
    dialog: false,
    dialogData: [],
    dialogTitle: "",
    dates: ["2021-09-28", "2021-09-30"],
    reportDialog: false,
    reportPoints: [],
    linePoints: [],
    midPoints: [],
    items: [],
    minX: 99999,
    maxX: -99999,
    minY: 99999,
    maxY: -99999,
    firstOpen: true,
    headers: [
      {
        text: "Numéro ligne",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Latitude", value: "lat" },
      { text: "Longitude", value: "lon" },
      { text: "Torque (calc)", value: "torque_calc" },
      { text: "Altitude", value: "altitude" },
      { text: "Delta Z", value: "deltaZ" },
      { text: "Date", value: "date" },
      { text: "Action", value: "action" },
      { text: "Angle X", value: "angleX" },
      { text: "Angle Y", value: "angleY" },
      { text: "Laser", value: "hauteur" },
      { text: "Drilling", value: "isDrilling" },
      { text: "consecutiveDrilling", value: "consecutiveDrilling" },
    ],
    value: 0,
    isSelecting: false,
    snackbar: false,
    text: "",
    minTorque: 0,
    maxTorque: 0,
    threshold: 0,
    chartOptions: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 350,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: [],
        xaxis: [],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
    series1: [
      {
        data: [],
      },
      {
        data: [],
      },
    ],

    series2: [
      {
        data: [],
      },
    ],
    reportData: [],
    user: null,
    circle: null,
  }),
  mounted() {
    const scope = this;

    this.$refs.gmap.$mapPromise.then((map) => {
      this.gmap = map;
    });
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    ...mapMutations({
      setData: "setData",
      setReportData: "setReportData",
      setUser: "setUser",
    }),
    avg(v) {
      return v.reduce((a, b) => a + b, 0) / v.length;
    },
    // On address selected, create map objects.
    onAddressSelected({ lat, lng, distanceThreshold }) {
      console.log("ADDRESS SELECTED", lat, lng);
      const scope = this;

      const center = {
        lat: lat,
        lng: lng,
      };

      if (!this.circle) {
        this.circle = new google.maps.Circle({
          draggable: true,
          editable: true,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map: this.gmap,
          center: center,
          radius: distanceThreshold ? distanceThreshold : 200,
        });

        google.maps.event.addListener(
          this.circle,
          "radius_changed",
          function (event) {
            console.log("circle radius changed");
            //console.log(circle.getRadius().toString())
            scope.$refs.CreateReportComponent.setNewRadius(
              parseInt(scope.circle.getRadius().toString())
            );
          }
        );

        google.maps.event.addListener(
          this.circle,
          "center_changed",
          function (event) {
            var newCenter = scope.circle.getCenter();
            console.log(
              "center changed: " + newCenter.lat() + ", " + newCenter.lng()
            );

            scope.$refs.CreateReportComponent.setNewCenter({
              lat: newCenter.lat(),
              lng: newCenter.lng(),
            });
          }
        );
      } else {
        this.circle.setCenter(new google.maps.LatLng(center.lat, center.lng));

        console.log("Set distance threshold");
        if (distanceThreshold) {
          this.circle.setRadius(distanceThreshold);
        }
      }

      var bounds = new google.maps.LatLngBounds();

      bounds.extend({ lat: lat, lng: lng });

      this.gmap.fitBounds(bounds);
    },
    // La carte leaflet est importante, permet de me donner des points X, Y simple pour dessiner le Canvas.
    mapReady() {
      this.map = this.$refs.myMap.mapObject;
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        className: "map-tiles",
      }).addTo(this.map);
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    drawReportPiles({ data, reportInfos }) {
      const scope = this;
      scope.reportData = data;
      let latlngs = [];
      let latlngsGmap = [];

      let numberOfPoints = 0;

      // PREPARE POINTS TO DRAW POLYGON
      for (let i = 0; i < data.data.final.length; i++) {
        latlngs.push([
          data.data.final[i].centroid.lat,
          data.data.final[i].centroid.lon,
        ]);
        latlngsGmap.push({
          lat: data.data.final[i].centroid.lat,
          lng: data.data.final[i].centroid.lon,
        });
        numberOfPoints++;
      }

      /*this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({ lat: 1.38, lng: 103.8 });
      }); */

      // Dessiner le polygone dans GoogleMaps
      var polygon = L.polygon(latlngs, { color: "red" }).addTo(scope.map);
      scope.map.fitBounds(polygon.getBounds()); // , { maxZoom: 17 }

      const polygonGmap = new google.maps.Polygon({
        paths: latlngsGmap,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      //this.gmap.$mapPromise.then((map) => {
      polygonGmap.setMap(this.gmap);
      var bounds = new google.maps.LatLngBounds();
      latlngsGmap.forEach(function (latLng) {
        bounds.extend(latLng);
      });
      this.gmap.fitBounds(bounds);
      //});
      // END DRAW POLYGON

      reportInfos.latlngsGmap = latlngsGmap;

      console.log("THE POLYGON", polygon);

      scope.setData(data.data.final);
      let pos = polygon._parts.toString();
      let splitted = pos
        .replaceAll("Point(", "")
        .replaceAll(")", "")
        .replaceAll(" ", "")
        .trim()
        .split(",");

      console.log(splitted);

      for (let p = 0; p < numberOfPoints; p++) {
        let point = {
          x: parseInt(splitted[p * 2]),
          y: parseInt(splitted[p * 2 + 1]),
        };

        if (numberOfPoints > 1) {
          scope.linePoints.push(point.x);
          scope.linePoints.push(point.y);
        }

        if (point.x < scope.minX) {
          scope.minX = point.x;
        }
        if (point.y < scope.minY) {
          scope.minY = point.y;
        }
        if (point.x > scope.maxX) {
          scope.maxX = point.x;
        }
        if (point.y > scope.maxY) {
          scope.maxY = point.y;
        }

        point.data = data.data.final[p];
        point.distanceFromLast = 0;

        if (p > 0) {
          //console.log(point);
          try {
            point.distanceFromLast = getDistance(
              point.data.centroid,
              scope.reportPoints[p - 1].data.centroid
            );
          } catch (e) {
            console.log(e);
          }
        }

        if (numberOfPoints > 1 && p == numberOfPoints - 1) {
          scope.reportPoints[0].distanceFromLast = getDistance(
            point.data.centroid,
            scope.reportPoints[0].data.centroid
          );
        }

        point.text = "";
        point.prof = "";
        point.torq = "";
        point.reportId = "";

        scope.reportPoints.push(point);
      }

      // Réajouter le premier point comme dernier point pour fermer le polygone.
      if (numberOfPoints > 1) {
        scope.linePoints.push(scope.reportPoints[0].x);
        scope.linePoints.push(scope.reportPoints[0].y);
      }

      scope.recalculatePointsAndLines(reportInfos);
    },
    // Trouver le point millieu d'une ligne droite.
    midpoint(x1, x2, y1, y2) {
      return { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
    },
    // Calculer le dessin et les textes dans le centre des lignes du polygone.
    recalculatePointsAndLines(reportInfos) {
      let scope = this;
      scope.midPoints = [];
      scope.items = [];
      scope.drawLinePoints = [];
      console.log(this.maxX, this.minX, this.maxY, this.minY);
      let maxLengthX = this.maxX - this.minX;
      let maxLengthY = this.maxY - this.minY;

      let ratioX = 570 / maxLengthX;
      let ratioY = 570 / maxLengthY;

      this.reportPoints.forEach((point, index) => {
        point.x = (point.x - scope.minX) * ratioX + 50;
        point.y = (point.y - scope.minY) * ratioY + 50;

        if (index > 0) {
          scope.midPoints.push(
            scope.midpoint(
              point.x,
              scope.reportPoints[index - 1].x,
              point.y,
              scope.reportPoints[index - 1].y
            )
          );
        }

        this.items.push({ text: `TP #`, reportId: index + 1 });
      });

      if (this.reportPoints.length > 1) {
        scope.midPoints.push(
          scope.midpoint(
            scope.reportPoints[scope.reportPoints.length - 1].x,
            scope.reportPoints[0].x,
            scope.reportPoints[scope.reportPoints.length - 1].y,
            scope.reportPoints[0].y
          )
        );

        scope.midPoints.forEach((point, index) => {
          if (index >= scope.reportPoints.length - 1) {
            point.distanceFromLast = scope.reportPoints[0].distanceFromLast;
          } else {
            point.distanceFromLast =
              scope.reportPoints[index + 1].distanceFromLast;
          }
        });
      }

      console.log(scope.linePoints);

      this.linePoints.forEach((point, index) => {
        let newPoint = 0;

        if (index % 2 == 0) {
          newPoint = (point - scope.minX) * ratioX + 50;
        } else {
          newPoint = (point - scope.minY) * ratioY + 50;
        }

        scope.linePoints[index] = newPoint;
      });

      const infos = {
        linePoints: scope.linePoints,
        reportPoints: scope.reportPoints,
        midPoints: scope.midPoints,
        items: scope.items,
        minX: scope.minX,
        maxX: scope.maxX,
        minY: scope.minY,
        maxY: scope.maxY,
        address: reportInfos.address,
        dates: reportInfos.dates,
        numberOfPiles: reportInfos.numberOfPiles,
        reportImg: null,
        latlngsGmap: reportInfos.latlngsGmap,
      };

      // Enregistrer le reportData et le mettre dans le VueX Store.
      // N'importe quel page peut y accéder par la suite.
      scope.setReportData(infos);

      scope.$router.push("/report");
    },
    resetMap() {
      location.reload();
    },
    logOut() {
      this.setUser(null);
      this.$router.push("/");
    },
    onCreateBack() {
      this.currentState = pageStates.LIST;
    },
  },
};
</script>
<style lang="scss">
#map {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 56px;
}

.error {
  color: red;
}

.leaflet-popup-content {
  max-height: 250px;
  width: 600px !important;
  overflow-y: scroll;
}

.file-input {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9999;
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg)
    saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .map-tiles {
    filter: var(--map-tiles-filter, none);
  }
}

.leaflet-touch .leaflet-bar a:first-child {
  background: #131e30 !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  background: #131e30 !important;
}

.leaflet-touch .leaflet-control-attribution {
  background: #131e30 !important;
  color: #fff;
}

.error-back {
  color: #ff1744;
}

.vissage-back {
  background: #272727;
}

.less-25 {
  color: gray;
}

.between-2550 {
  color: darkcyan;
}

.between-5075 {
  color: darkgoldenrod;
}

.over-75 {
  color: darkred;
}

.height-57 {
  height: 57% !important;
}
</style>
