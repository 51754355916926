<template>
  <div style="height: 100%">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col xl="2" lg="3" md="4" sm="5" xs="12">
        <v-card
          style="border-radius: 12px; background-color: #fff"
          class="pa-12"
          elevation="10"
        >
          <v-form ref="logForm" action="#" v-model="validLogin">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-img
                  class="ma-6"
                  style="margin: auto"
                  lazy-src="terrain.png"
                  src="terrain.png"
                ></v-img>
              </v-col>

              <v-col cols="12" v-show="step == 0">
                <v-text-field
                  id="username"
                  type="email"
                  class="form-control"
                  name="username"
                  value
                  :rules="[
                    (v) =>
                      !v ||
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      'Le courriel doit être valide.',

                    (v) => !!v || 'Le courriel est requis.',
                  ]"
                  label="Courriel"
                  required
                  autofocus
                  color="primary"
                  autocomplete="on"
                  v-model="form.username"
                  @keydown.enter.prevent="nextStep"
                  outlined
                />
              </v-col>

              <v-col cols="12" v-show="step == 1">
                <v-text-field
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  label="Mot de passe"
                  required
                  outlined
                  autofocus
                  autocomplete="on"
                  @keydown.enter.prevent="submit"
                  v-model="form.password"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-btn
            v-if="step == 1"
            fab
            absolute
            left
            bottom
            color="#131E30"
            class="mt-5"
            dark
            @click="goBack"
          >
            <v-icon>mdi-arrow-u-left-top</v-icon>
          </v-btn>

          <v-btn
            fab
            absolute
            right
            bottom
            :loading="step == 0 ? loadingStep0 : loadingStep1"
            type="submit"
            color="#131E30"
            class="mt-5"
            dark
            @click="step == 1 ? submit() : nextStep()"
          >
            <v-icon>mdi-location-enter</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      multi-line
      color="error"
      v-model="errorLogin"
      :timeout="5000"
      width="200px"
    >
      <v-row>
        <v-col cols="2">
          <v-icon>mdi-alert-outline</v-icon>
        </v-col>

        <v-col>
          {{ errorText }}
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      step: 0,
      validLogin: false,
      error: null,
      loadingStep0: false,
      loadingStep1: false,
      errorLogin: false,
      errorText: "Authentification invalide",
    };
  },
  methods: {
    ...mapMutations({
      setUser: "setUser",
    }),
    ...mapActions({
      signInWithEmailAndPassword: "signInWithEmailAndPassword",
    }),
    async submit() {
      this.loadingStep1 = true;
      this.$refs.logForm.validate();
      setTimeout(async () => {
        if (this.validLogin) {
          try {
            const userCredential = await this.signInWithEmailAndPassword({
              username: this.form.username,
              password: this.form.password,
            });

            if (userCredential) {
              this.$router.replace({ name: "Map" });
            }
          } catch (e) {
            this.errorText = e.message;
            this.error = e.message;
            this.errorLogin = true;
          } finally {
            this.loadingStep1 = false;
          }
        }
      }, 500);
    },
    goBack() {
      this.step = 0;
    },
    nextStep() {
      this.loadingStep0 = true;
      this.$refs.logForm.validate();
      setTimeout(() => {
        if (this.validLogin) {
          this.step = 1;
        } else {
          this.errorText =
            "Courriel non valide. Vérifiez s’il y a des fautes de frappe et si l’adresse courriel est au bon format. ";
          this.errorLogin = true;
        }

        this.loadingStep0 = false;
      }, 800);
    },
  },
};
</script>
<style></style>
