import Vue from "vue";
import Vuex from "vuex";
import $axios from "../axios";
import router from "../router";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    /** GoogleDebug.vue */
    debugData: [],
    /** Report.vue */
    reportData: {},
    /** Raw.vue */
    rawData: [],
    /** Liste de rapports @deprecated */
    reports: [],
    /** accessToken. */
    user: null,
  },
  mutations: {
    setDebugData(state, data) {
      state.debugData = data;
    },
    setRawData(state, data) {
      state.rawData = data;
    },
    setReportData(state, data) {
      state.reportData = data;
    },
    setReports(state, data) {
      state.reports = data;
    },
    setUser(state, data) {
      state.user = data;
    },
  },
  actions: {
    /**
     * Initialiser l'utilisateur si il existe.
     */
    init({ commit }) {
      // Set accessToken if already defined in localStorage
      const accessToken = localStorage.getItem("accessToken");
      commit("setUser", accessToken);
    },

    /**
     * Log out.
     */
    logOut({ commit }) {
      console.log("LOGGING OUT");
      router.push("/");
    },
    /** Sign in */
    async signInWithEmailAndPassword({ commit }, { username, password }) {
      const userCred = await $axios.post("/api/auth", {
        username: username,
        password: password,
      });

      localStorage.setItem("accessToken", userCred.data);

      commit("setUser", userCred.data);

      return userCred;
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    isLoggedIn(state) {
      return state.user != null;
    },
  },
  modules: {},
});

export default store;
