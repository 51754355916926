<template>
  <v-menu
    v-model="tuneMenu"
    :close-on-content-click="false"
    :nudge-width="500"
    style="z-index: 99999"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        absolute
        fab
        dark
        style="z-index: 9999; right: 15px; top: 15px"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-tune-variant</v-icon>
      </v-btn>
    </template>

    <v-card style="border: 1px solid #333; border-radius: 10px">
      <v-list>
        <v-list-item>
          <h5 class="ml-5">Distance pieux / adresse</h5>
          &nbsp;&nbsp;
          <v-text-field
            style="display: inline-block"
            :value="distanceThreshold"
            @input="
              (event) => $emit('updateDistanceThreshold', event.target.value)
            "
            hide-details
            single-line
            rounded
            filled
            append-icon="mdi-map-marker-distance"
            type="number"
          />
        </v-list-item>
        <br />
        <v-divider inset />
        <br />
        <v-list-item>
          <h5 class="ml-5">Formule de pression</h5>
          &nbsp;&nbsp;
          <v-text-field
            style="display: inline-block"
            :value="torqueFormula"
            @input="(event) => $emit('updateTorqueFormula', event.target.value)"
            hide-details
            rounded
            filled
            append-icon="mdi-calculator"
            type="text"
          />
          <br />
        </v-list-item>
        <br />
        <p style="font-size: 10px; margin-left: 35px">
          Variables: torque_dec & torque_hex sont disponibles. À noter que
          torque_hex est en base 16.
        </p>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="primary"
          text
          @click="$emit('updateTuneSet', !tuneSet)"
        >
          <Transition name="slide-fade">
            <v-icon v-if="tuneSet" color="green"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
          </Transition>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    /**
     * Si les settings sont différents de par défaut.
     */
    tuneSet: {
      type: Boolean,
      default: false,
    },
    /**
     * Distance entre les points et l'adresse du chantier. ( En mètre )
     */
    distanceThreshold: {
      type: Number,
      default: 2000,
    },
    /**
     * Formule servant à calculer le torque.
     * @deprecated - Ne sert plus vu que s3p-tcp fait le travail.
     */
    torqueFormula: {
      type: String,
      default: "(torque_dec - 16384) * 4.25522",
    },
  },
  data() {
    return {
      tuneMenu: false,
    };
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style></style>
