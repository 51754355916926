import store from "../store";
import $axios from "../axios";

export default function setup() {
  /**
   *  Les interceptors sont une manière commune d'injecter le token dans chacune des requêtes.
   */
  $axios.interceptors.request.use(
    function (config) {
      const token = store.getters.user;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  /**
   *  Si une erreur de unauthorized, logOut.
   */
  $axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch("logOut");
      }
      return error;
    }
  );
}
