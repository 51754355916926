import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VBtn,{staticClass:"no-transform",style:({
    'z-index': 9999,
    left: _vm.left,
    top: _vm.top,
    right: _vm.right,
    bottom: _vm.bottom,
  }),attrs:{"rounded":"","color":_vm.color,"dark":"","large":"","absolute":_vm.absolute,"loading":_vm.loading},on:{"click":_vm.onClick}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }