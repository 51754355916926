<template>
  <v-menu
    v-model="savedGenMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    style="z-index: 99999"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        absolute
        fab
        dark
        style="z-index: 9999; right: 15px; top: 90px"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-clipboard-list-outline</v-icon>
      </v-btn>
    </template>

    <v-card style="border: 1px solid #8c8c8c; border-radius: 10px">
      <v-list>
        <v-list-item
          v-for="item in savedGenerations"
          :key="item.uuid"
          @click="handleSelected(item)"
        >
          {{ item.address }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
const NOMBRE_DE_GENERATION_A_SAUVEGARDER = 10;

export default {
  props: {},
  data() {
    return {
      /**
       * Entrées enregistrés
       * { address: '',
       *   lat: '',
       *   lng: '',
       *   startDate: '',
       *   endDate: '',
       *   numberOfPiles: 0,
       *   distanceThreshold: 2000,
       *   tuneSet: true
       * }[]
       */
      savedGenerations: [],
      /**
       * Afficher ou pas le menu.
       */
      savedGenMenu: false,
    };
  },
  methods: {
    handleSelected(item) {
      /**
       * Envoyer la génération sélectionnée.
       * { address: '',
       *   lat: '',
       *   lng: '',
       *   startDate: '',
       *   endDate: '',
       *   numberOfPiles: 0,
       *   distanceThreshold: 2000,
       *   tuneSet: true
       * }
       */
      this.$emit("handleSelected", item);
    },
    /**
     * Enregistrer la génération dans le localStorage.
     * @param {address: '', lat: '', lng: '', startDate: '', endDate: '', numberOfPiles: 0, distanceThreshold: 2000, tuneSet: true} configToSave
     */
    saveToLocalStorage(configToSave) {
      if (this.savedGenerations.length > 0) {
        /**
         * Seulement 10 entrées.
         */
        const array = [
          configToSave,
          ...this.savedGenerations.slice(0, NOMBRE_DE_GENERATION_A_SAUVEGARDER),
        ];
        localStorage.setItem("generations", JSON.stringify(array));
        this.savedGenerations = array;
      } else {
        const array = [configToSave];
        localStorage.setItem("generations", JSON.stringify(array));
        this.savedGenerations = array;
      }

      console.log("PAST GEN", this.savedGenerations);
    },
    /**
     * Récupérer les 10 dernières générations du localStorage.
     */
    getFromLocalStorage() {
      try {
        const savedGen = JSON.parse(localStorage.getItem("generations"));
        if (savedGen) {
          this.savedGenerations = savedGen;
        }
        console.log("GET GENS", savedGen);
      } catch (e) {}
    },
  },
  mounted() {
    /**
     * Récupérer les générations sur mounted.
     */
    this.getFromLocalStorage();
  },
  created() {},
};
</script>
<style>
.no-transform {
  text-transform: none;
}
</style>
