<template>
  <div>
    <v-stage
      ref="stage"
      style="margin-left: calc(50% - 335px)"
      :config="{ width: 670, height: 670 }"
    >
      <v-layer ref="layer">
        <v-text
          v-for="(item, index) in localReportPoints"
          :key="'textPoint' + index"
          :config="{
            x: localReportPoints.length > 1 ? item.x + 5 : 285 + 5,
            y: localReportPoints.length > 1 ? item.y + -30 : 285 + -30,
            text: item.text + item.reportId ? item.text + item.reportId : '',
          }"
        ></v-text>
        <v-text
          v-for="(item, index) in localMidPoints"
          @click="clickPile(item)"
          :key="'text' + index"
          :config="{
            x: item.x + 5,
            y: item.y + -20,
            text: item.distanceFromLast.toFixed(2) + 'm',
          }"
        ></v-text>
        <v-line
          :config="{
            points: localLinePoints,
            stroke: 'black',
          }"
        />
        <!-- @contextmenu="show($event, item)" -->
        <v-circle
          v-for="(item, index) in localReportPoints"
          :key="'circle' + index"
          :config="{
            x: localReportPoints.length > 1 ? item.x : 285 + 50,
            y: localReportPoints.length > 1 ? item.y : 285 + 50,
            radius: 10,
            fill: 'red',
          }"
        ></v-circle>
      </v-layer>
    </v-stage>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Pieux No</th>
            <th class="text-left">Modèle</th>
            <th class="text-left">Prof finale</th>
            <th class="text-left">Couple final</th>
            <th class="text-left">Capacité</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in localReportPoints.filter((i) => i.reportId !== '')"
            :key="item.uuid"
          >
            <td>{{ item.text + item.reportId }}</td>
            <td></td>
            <td v-show="item.profImperial">
              {{ item.prof + `m (${item.profImperial})` }}
            </td>
            <td v-show="item.lastTorq">{{ item.lastTorq + ` lb.ft ` }}</td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-text-field
        autofocus
        type="number"
        v-model="id"
        filled
        hide-details
        @keydown.enter="
          (showMenu = false), itemClicked($event, { reportId: id })
        "
      />
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PileCanvas",
  props: {
    /** Points des lignes du canvas à dessiner */
    linePoints: Array,
    /** Points du rapport, final clusters */
    reportPoints: Array,
    /** Point milieux des lignes pour le texte */
    midPoints: Array,
    /**  */
    items: Array,
  },
  data: () => ({
    localLinePoints: [],
    localReportPoints: [],
    localMidPoints: [],
    localItems: [],
    showMenu: false,
    x: 0,
    y: 0,
    selectedPile: null,
    id: "",
  }),
  mounted() {
    console.log("MOUNTED");
    const scope = this;
    this.localLinePoints = [];
    this.localReportPoints = [];
    this.localMidPoints = [];
    this.localItems = [];
    try {
      this.localLinePoints = JSON.parse(JSON.stringify(this.linePoints));
      this.localReportPoints = JSON.parse(JSON.stringify(this.reportPoints));
      this.localMidPoints = JSON.parse(JSON.stringify(this.midPoints));
      this.localItems = JSON.parse(JSON.stringify(this.items));
    } catch (e) {
      this.$router.push("/map");
    }
    //console.log(this.reportData.items);

    if (this.reportData.items) {
      this.localReportPoints.forEach((item, index) => {
        item.text = "TP #";
        item.reportId = this.reportData.items[index].reportId;
        this.findPhases(item);
      });
      this.localReportPoints.sort((a, b) => {
        return a.reportId - b.reportId;
      });
      this.$emit(
        "onPileSelected",
        this.localReportPoints.filter((point) => point.reportId != "")
      );
    }
  },
  computed: {
    ...mapState({
      reportData: (state) => state.reportData,
    }),
  },
  watch: {},
  methods: {
    show(e, item) {
      e.evt.preventDefault();
      this.selectedPile = item;
      // this.findPhases(item);

      this.showMenu = false;
      this.x = e.evt.clientX;
      this.y = e.evt.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    itemClicked(e, item) {
      e.preventDefault();
      this.id = "";

      this.localReportPoints.forEach((point, index) => {
        if (point.reportId == item.reportId) {
          point.reportId = "";
        }
      });

      console.log("SELECTEDPILE", this.selectedPile);

      this.selectedPile.text = "TP #";
      this.selectedPile.reportId = item.reportId;

      this.localReportPoints.sort((a, b) => {
        return a.reportId - b.reportId;
      });

      this.$emit(
        "onPileSelected",
        this.localReportPoints.filter((point) => point.reportId != "")
      );
    },
    findPhases(item) {
      const dataX = item.data.elements;
      let lastRow = null;
      let currentPhase = [];
      let phases = [];

      //const sortedDates = dataX.slice().sort((a, b) => b.date - a.date);

      dataX.forEach((element) => {
        const currentRow = element;
        if (lastRow) {
          const diff =
            new Date(currentRow.Horodatage).getTime() -
            new Date(lastRow.Horodatage).getTime();
          if (Math.abs(diff) >= 300000) {
            // 5mins

            // If there is more than 40 items, good drill
            // Check if last is deeper than 100cm.
            if (
              currentPhase.length > 30 &&
              //currentPhase[currentPhase.length - 1].deltaZAltitude > 1.0
              currentPhase[currentPhase.length - 1].deltaZ > 100
            ) {
              phases.push(currentPhase);
            }

            currentPhase = [];
          }

          currentPhase.push(currentRow);
        } else {
          currentPhase.push(currentRow);
        }

        lastRow = currentRow;
      });

      phases.push(currentPhase);

      //console.log(phases);
      item.phases = phases;
      item.uuid = item.data.uuid;
      this.findProf(item);
    },
    findProf(item) {
      let prof = 0;
      let lastTorq = 0;
      item.ralls = [];

      try {
        for (let i = 0; i < item.phases.length; i++) {
          // Using deltaZ
          const pileOrRallProf = Math.max(
            ...item.phases[i].map((o) => o.deltaZ)
          );
          // Using deltaAlt
          const pileOrRallProfAlt = Math.max(
            ...item.phases[i].map((o) => o.deltaZAltitude)
          );

          if (i == 0) {
            item.torq = item.phases[i].find(
              (o) => o.deltaZ == pileOrRallProf
            ).Torque;
            //"lb.ft";
            lastTorq = item.torq;
          } else {
            const rallTorq = Math.max(...item.phases[i].map((o) => o.Torque));

            item.ralls.push({
              id: i,
              text: "Rall #",
              //prof: pileOrRallProfAlt,
              prof: pileOrRallProf / 100, //deltaZ M.T
              torq: rallTorq, //+ "lb.ft",
            });
            lastTorq = rallTorq;
          }
          //prof += pileOrRallProfAlt;
          prof += pileOrRallProf; // deltaZ M.T
        }
      } catch (e) {}

      item.prof = prof;
      item.prof = prof / 100; //deltaZ M.T
      //var inches = (prof * 100 * 0.393700787).toFixed(0);
      var inches = (prof * 0.393700787).toFixed(0); //deltaZ M.T
      var feet = Math.floor(inches / 12);
      inches %= 12;
      item.profImperial = feet + "ft " + inches + "in";
      item.lastTorq = lastTorq;
    },
  },
};
</script>
<style>
.v-menu__content {
  background: white;
  box-shadow: none;
  z-index: 9999999;
  overflow: hidden;
}
</style>
