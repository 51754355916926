import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueKonva from "vue-konva";
import interceptorsSetup from "./helpers/interceptors";
import axios from "./axios";
Vue.config.productionTip = true;
import "leaflet/dist/leaflet.css";

const instance = axios;

// Vue Google Maps with Autocomplete
import * as VueGoogleMaps from "vue2-google-maps-withscopedautocomp"; // Import package
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAP_KEY, // Google Maps API
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

Vue.use(VueKonva);

Vue.prototype.$axios = instance;

// Setup interceptors to add Bearer token and redirect if 401.
interceptorsSetup();

new Vue({
  router,
  store,
  vuetify,

  render: function (h) {
    return h(App);
  },
}).$mount("#app");
