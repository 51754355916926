const R = 6378137; // Ellipsoid de la terre du pôle nord au pôle sud.
const PI_360 = Math.PI / 360;

/**
 *  Distance de 2 points GPS - Fomule d'Haversine
 * @param { lat: Latitude, lon: Longitude } a
 * @param { lat: Latitude, lon: Longitude } b
 * @returns - Distance en mètre entre les deux points.
 */
export const getDistance = (a, b) => {
  const cLat = Math.cos((a.lat + b.lat) * PI_360);
  const dLat = (b.lat - a.lat) * PI_360;
  const dLon = (b.lon - a.lon) * PI_360;

  const f = dLat * dLat + cLat * cLat * dLon * dLon;
  const c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f));

  return R * c;
};

/**
 * Obtenir la moyenne de la donnée précédente, présente et prochaine.
 * @param {[ previous, current , next ]} v
 * @returns - La moyenne des 3 valeurs.
 */
const avg = (v) => {
  return v.reduce((a, b) => a + b, 0) / v.length;
};

/**
 * Sert à faire un tracé plus smooth.
 * @param {Array} - Vecteur à rendre plus smooth.
 * @param {number} variance - Le facteur de variance.
 * @returns - Le vecteur "smoothé"
 */
export const smoothOut = (vector, variance) => {
  var scope = this;
  var t_avg = avg(vector) * variance;
  var ret = Array(vector.length);
  for (var i = 0; i < vector.length; i++) {
    (function () {
      var prev = i > 0 ? ret[i - 1] : vector[i];
      var next = i < vector.length ? vector[i] : vector[i - 1];
      ret[i] = avg([t_avg, avg([prev, vector[i], next])]);
    })();
  }
  return ret;
};
