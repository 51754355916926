import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{staticStyle:{"z-index":"99999"},attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"z-index":"9999","right":"15px","top":"90px"},attrs:{"absolute":"","fab":"","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-clipboard-list-outline")])],1)]}}]),model:{value:(_vm.savedGenMenu),callback:function ($$v) {_vm.savedGenMenu=$$v},expression:"savedGenMenu"}},[_c(VCard,{staticStyle:{"border":"1px solid #8c8c8c","border-radius":"10px"}},[_c(VList,_vm._l((_vm.savedGenerations),function(item){return _c(VListItem,{key:item.uuid,on:{"click":function($event){return _vm.handleSelected(item)}}},[_vm._v(" "+_vm._s(item.address)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }