<template>
  <div style="background: white">
    <v-card elevation="0" style="margin: auto; max-width: 670px">
      <v-card-title>
        <v-btn
          class="no-print"
          icon
          absolute
          left
          top
          @click="$router.push('/map')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <h3 class="ml-5"></h3>
      </v-card-title>

      <br />

      <img class="tp-img" width="400" :src="'./logo-technopieux.svg'" />

      <br />
      {{ "Adresse :" + reportData.address }}

      <br />
      {{ "Nombre de pieux :" + reportData.numberOfPiles }}

      <br />
      {{ "Dates :" + reportData.dates }}

      <div>
        <GmapMap
          :zoom="zoom"
          :center="centerGmap"
          ref="gmap"
          style="height: 670px; max-width: 670px"
          :options="{ mapTypeId: 'satellite', disableDefaultUI: true }"
        >
        </GmapMap>
      </div>

      <br />

      <h3 class="ml-5">Représentation</h3>

      <PileCanvas
        :line-points="reportData.linePoints"
        :report-points="reportData.reportPoints"
        :mid-points="reportData.midPoints"
        :items="reportData.items"
        @onPileSelected="onPileSelected"
      />
    </v-card>
    <div>
      <v-card class="ml-5 mt-5 mb-5">
        <v-card-title class="text-center justify-center py-6">
          <h1 class="font-weight-bold text-h2">
            PILES
          </h1>
        </v-card-title>
        <v-tabs v-model="tab" bg-color="primary">
          <v-tab v-for="item in selectedPiles" :href="'#tab_' + item.reportId" :key="item.reportId" :value="item.reportId"><span style="color: black;display: flex;width: 100%;height: 100%;align-items: center;background-color: #FAFAFA;place-content: center;">TP #{{ item.reportId }}</span></v-tab>
          <v-tab-item v-for="item in selectedPiles" :id="'tab_' + item.reportId" :value="item.reportId" :key="item.reportId">
            <div class="ml-5 mt-5 mb-5">
              <h3>TP #{{ item.reportId }} - Angles</h3>

              {{ `Pression Max: ${item.lastTorq} lb.ft à ${item.profImperial}` }}

              <br />
              {{
                `Profondeur finale: ${item.profImperial} à ${item.lastTorq} lb.ft`
              }}

              <br />
              {{ getAvgAngles(item.data.elements) }}

              <br />
              {{ getFinalAngles(item.data.elements) }}
            </div>

            <AngleChart :items="item.data.elements" />

            <AnglePicto />

            <h3>TP #{{ item.reportId }} - Couple en fonction de la profondeur</h3>

            <TorqueVsProfChart :items="item.data.elements" />

            <h3>
              TP #{{ item.reportId }} - Capacité portante en fonction de la
              profondeur
            </h3>

            <CapaVsProfChart :items="item.data.elements" />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import PileCanvas from "@/components/PileCanvas.vue";
import AngleChart from "@/components/charts/AngleChart.vue";
import TorqueVsProfChart from "@/components/charts/TorqueVsProfChart.vue";
import CapaVsProfChart from "@/components/charts/CapaVsProfChart.vue";
import AnglePicto from "@/components/icons/AnglePicto.vue";
import RoundButton from "@/components/buttons/RoundButton";

const R = 6378137;
const PI_360 = Math.PI / 360;

export default {
  name: "Report",
  components: {
    PileCanvas,
    AngleChart,
    TorqueVsProfChart,
    CapaVsProfChart,
    AnglePicto,
    RoundButton,
  },
  props: {},
  data: () => ({
    selectedPiles: [],
    avgX: 0,
    avgY: 0,
    gmap: undefined,
    zoom: 7,
    centerGmap: { lat: 46.05030879999986, lng: -71.38202359999987 },
  }),
  mounted() {
    // Utiliser le reportData créer dans Map.vue pour le rapport.
    this.gmap = this.$refs.gmap;
    const scope = this;

    const latlngsGmap = scope.reportData.latlngsGmap;

    const polygonGmap = new google.maps.Polygon({
      paths: latlngsGmap,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    this.gmap.$mapPromise.then((map) => {
      polygonGmap.setMap(map);
      var bounds = new google.maps.LatLngBounds();

      latlngsGmap.forEach(function (latLng) {
        bounds.extend(latLng);
      });

      // bounds.extend(myLatLng);
      map.fitBounds(bounds);
    });

    latlngsGmap.forEach((latLng) => {
      this.gmap.$mapPromise.then((map) => {
        const markerGmap = new google.maps.Circle({
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          strokeWeight: 0,
          map: scope.gmap,
          center: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
          radius: 2,
        });

        markerGmap.setMap(map);
      });
    });
  },
  computed: {
    ...mapState({
      reportData: "reportData",
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "setUser",
    }),
    onPileSelected(piles) {
      this.selectedPiles = [];
      this.selectedPiles = piles;
    },
    getAvgAngles(items) {
      let avgX = 0;
      let avgY = 0;
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        avgX += item.Angle_X;
        avgY += item.Angle_Y;
      }

      avgX = avgX / items.length;
      avgY = avgY / items.length;

      return `Angles moyens en X: ${Math.round(avgX)}, en Y: ${Math.round(
        avgY
      )}`;
    },
    getFinalAngles(items) {
      return items.length > 0
        ? `Angles finaux en X: ${items[items.length - 1].Angle_X}, en Y: ${
            items[items.length - 1].Angle_Y
          }`
        : "";
    },
  },
};
</script>
<style lang="scss">
.v-dialog__content {
  z-index: 9999 !important;
}

h3 {
  page-break-before: always;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
