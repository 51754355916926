<template>
  <div style="text-align: right">
    <!--
    Icône noir en bas à droite du graphique de l'angle. X Y
  -->
    <svg
      width="142"
      height="96"
      viewBox="0 0 284 193"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="125" cy="143" r="50" fill="black" />
      <rect y="126" width="119" height="33" fill="black" />
      <path
        d="M125 36L110.566 61H139.434L125 36ZM127.5 88V58.5H122.5V88H127.5Z"
        fill="black"
      />
      <path
        d="M239 143L214 128.566V157.434L239 143ZM180 145.5H216.5V140.5H180V145.5Z"
        fill="black"
      />
      <path
        d="M115.64 20.144V9.056H118.736V20.144H115.64ZM111.488 16.064V13.136H122.912V16.064H111.488ZM123.924 23L131.148 13.04V15.872L124.236 6.2H128.652L133.428 12.944L131.58 12.968L136.284 6.2H140.508L133.644 15.704V12.92L140.916 23H136.428L131.484 15.872H133.26L128.388 23H123.924Z"
        fill="black"
      />
      <path
        d="M252.64 148.144V137.056H255.736V148.144H252.64ZM248.488 144.064V141.136H259.912V144.064H248.488ZM266.738 151V144.136L267.626 146.488L260.234 134.2H264.362L270.026 143.632H267.65L273.338 134.2H277.154L269.762 146.488L270.626 144.136V151H266.738Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style>
.align-right {
  margin-left: auto;
}
</style>
