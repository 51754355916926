<template>
  <v-btn
    rounded
    :color="color"
    @click="onClick"
    dark
    class="no-transform"
    large
    :style="{
      'z-index': 9999,
      left: left,
      top: top,
      right: right,
      bottom: bottom,
    }"
    :absolute="absolute"
    :loading="loading"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    /**
     * Si le bouton est disabled et affiche un spinner.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Texte du bouton.
     */
    text: {
      type: String,
      default: "",
    },
    /**
     * Couleur de background du bouton.
     */
    color: {
      type: String,
      default: "#131E30",
    },
    /**
     * Si la position est absolue ou non.
     */
    absolute: {
      type: Boolean,
      default: true,
    },
    /**
     * Valeur de distance absolue gauche.
     */
    left: {
      type: String,
      default: "",
    },
    /**
     * Valeur de distance absolue droite.
     */
    right: {
      type: String,
      default: "",
    },
    /**
     * Valeur de distance absolue du haut.
     */
    top: {
      type: String,
      default: "",
    },
    /**
     * Valeur de distance absolue du bas.
     */
    bottom: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick(e) {
      /**
       * Lorsqu'on appuie sur le bouton.
       * Évènement onClick
       */
      this.$emit("onClick", e);
    },
  },
};
</script>
<style>
.no-transform {
  text-transform: none;
}
</style>
