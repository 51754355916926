<template>
  <div>
    <v-card style="height: 100%; border-radius: 20px" elevation="0" class="pt-5">
      <v-row justify="center" align="center">
        <v-col cols="12" align="center">
          <h5 class="ml-5">
            <v-icon>mdi-file-image-marker-outline</v-icon>

            <span class="ml-5">Ajout d'un rapport de chantier</span>
          </h5>
        </v-col>

        <v-col cols="3">
          <h5 class="ml-5">Adresse du chantier</h5>

          <gmap-autocomplete @place_changed="onChange">
            <template v-slot:input="slotProps">
              <v-text-field
                rounded
                ref="input"
                filled
                v-model="address"
                v-on:listeners="slotProps.listeners"
                v-on:attrs="slotProps.attrs"
              >
                <template v-slot:append>
                  <v-icon
                    slot="append-icon"
                    :color="addressSelected ? 'success' : 'error'"
                    >{{
                      addressSelected ? "mdi-check" : "mdi-map-marker"
                    }}</v-icon
                  >
                </template>
              </v-text-field>
            </template>
          </gmap-autocomplete>

          <h5 class="ml-5">Dates du chantier</h5>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                rounded
                filled
                v-model="dates"
                ref="dates"
                append-icon="mdi-calendar-range"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              no-title
              scrollable
              style="margin: auto"
              v-model="dates"
              range
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <span style="display: inline-flex;">
            <span style="margin-right: 5px;">
              <h5 class="ml-5">Nombre de pieux</h5>
              <v-text-field
                v-model="numberOfPiles"
                style="display: inline-block"
                hide-details
                single-line
                rounded
                filled
                append-icon="mdi-axis-z-rotate-clockwise"
                type="number"
              />
            </span>
            <span>
              <h5 class="ml-5">Machine</h5>
              <v-select return-object label="Machine" v-model="selectedMachine" name="selectedMachine" single-line rounded filled :items="machineList" v-on:change="setLocalStorage('selectedMachine', $event.value)">
                <template v-slot:item="{item}">
                  {{item.title}}
                </template>    
                <template v-slot:selection="{item}">
                  {{item.title}}
                </template>
              </v-select>
            </span>
          </span>
        </v-col>
      </v-row>

      <TuneMenu
        :tune-set="tuneSet"
        :distance-threshold="distanceThreshold"
        :torque-formula="torqueFormula"
        @updateTuneSet="(value) => (tuneSet = value)"
        @updateTorqueFormula="(value) => (torqueFormula = value)"
        @updateDistanceThreshold="(value) => (distanceThreshold = value)"
      />

      <SavedGenerationMenu ref="savedGen" @handleSelected="handleSelected" />

      <TextButton
        @onClick="handleDebugData"
        right="15px"
        bottom="130px"
        :loading="preparingReport"
      >
        {{ "Debug" }}
      </TextButton>

      <TextButton
        @onClick="handleReportGeneration"
        right="15px"
        bottom="80px"
        :loading="preparingReport"
      >
        {{ "Suivant" }}
      </TextButton>

      <TextButton
        @onClick="fetchRawData"
        right="15px"
        bottom="30px"
        :loading="preparingReport"
      >
        {{ "Données brutes" }}
      </TextButton>

      <TextButton
        @onClick="processAlgo()"
        right="200px"
        bottom="30px"
        :loading="preparingReport"
      >
        {{ "Rapport automatisé" }}
      </TextButton>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="messageTimeout"
    >
      {{ message }}

      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps-withscopedautocomp";

import RoundButton from "../components/buttons/RoundButton.vue";
import TextButton from "../components/buttons/TextButton.vue";
import SavedGenerationMenu from "../components/menus/SavedGenerationMenu.vue";
import TuneMenu from "../components/menus/TuneMenu.vue";
import RequestService from "@/utils/requestService";

export default {
  name: "CreateReport",
  components: { RoundButton, TextButton, SavedGenerationMenu, TuneMenu },
  data: () => ({
    address: "",
    addressSelected: false,
    lat: 46.11146129999999,
    lng: -71.290685,
    menu: false,
    numberOfPiles: 3,
    distanceThreshold: 2000,
    isLoading: false,
    dates: [new Date().toISOString().split("T")[0]],
    preparingReport: false,
    tuneMenu: false,
    tuneSet: false,
    torqueFormula: "(torque_dec - 16384) * 4.25522",
    message: null,
    messageTimeout: 4000,
    snackbar: false,
    machineList: null,
    selectedMachine: null
  }),
  computed: {
    google: gmapApi,
    ...mapState({}),
  },
  created() {
    console.log('created');
  },
  async mounted() {
    let machines = await this.$axios.get('/api/machineList');
    let preSelectedId = this.getLocalStorage('selectedMachine');
    this.machineList = [];
    for (let c of machines.data.data) {
      this.machineList.push({ title: c.noSerie, value: c.id });
      if (this.selectedMachine == null && (c.id == preSelectedId || preSelectedId == null)) this.selectedMachine = { title: c.noSerie, value: c.id };
    }
  },
  methods: {
    log: console.error,
    setLocalStorage: (item, value) => {
      console.log('setLocalStorage:', item, value);
      localStorage.setItem(item, value.toString());
    },
    getLocalStorage: (item) => {
      let value = localStorage.getItem(item);
      console.log('getLocalStorage:', item, value);
      if (value == null) return null;
      return value.toString();
    },
    setNewCenter(center) {
      //console.log('Set new center', center)
      this.lat = center.lat;
      this.lng = center.lng;
      this.addressSelected = true;
    },
    setNewRadius(radius) {
      //console.log('Set new radius', radius)
      this.distanceThreshold = radius;
      this.tuneSet = true;
    },
    onChange(input) {
      this.address = input.formatted_address;
      const scope = this;

      this.$gmapApiPromiseLazy({}).then(() => {
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ address: this.address }, (result, status) => {
          if (status == "OK") {
            scope.lat = result[0].geometry.location.lat();
            scope.lng = result[0].geometry.location.lng();

            scope.$emit("onAddressSelected", {
              lat: scope.lat,
              lng: scope.lng,
              radius: null,
            });

            scope.addressSelected = true;
          }
        });
      });
    },
    ...mapMutations(["setData", "setDebugData", "setRawData"]),
    async handleDebugData() {
      const scope = this;
      if (this.dates.length < 1) {
        this.$refs.dates.focus();
        return;
      }

      this.preparingReport = true;
      let startDate;
      let endDate;

      if (this.dates.length === 0) {
        alert("Veuillez insérer au moins une date.");
        return;
      }

      if (this.dates.length === 1) {
        startDate = new Date(this.dates[0]).setUTCHours(0, 0, 0, 0);
        endDate = new Date(this.dates[0]).setUTCHours(23, 59, 59, 999);
      } else {
        startDate = new Date(this.dates[0]).setUTCHours(0, 0, 0, 0);
        endDate = new Date(this.dates[1]).setUTCHours(23, 59, 59, 999);
      }
      console.log("NUMBER OF PILES", this.numberOfPiles);

      let numberOfPiles =
        this.numberOfPiles != "" && this.numberOfPiles !== 0
          ? this.numberOfPiles
          : null;

      let distanceThreshold = this.tuneSet ? this.distanceThreshold : null;
      let formula = this.tuneSet ? this.torqueFormula : null;

      var configToSave = {
        startDate,
        endDate,
        numberOfPiles,
        distanceThreshold,
        address: this.address,
        uuid: this.generateUUID(),
        date: Date.now(),
        tuneSet: this.tuneSet,
        formula,
        lat: this.lat,
        lng: this.lng,
      };

      this.$refs.savedGen.saveToLocalStorage(configToSave);

      try {
        const data = await this.$axios.post(
          "/generateDebug",
          {
            lat: this.lat,
            lng: this.lng,
            address: this.address,
            startDate: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(), //new Date("2021-09-30 13:26:00.000").toISOString(),
            numberOfPiles,
            distanceThreshold,
            formula,
          },
          {
            timeout: 20000,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        scope.isLoading = false;
        //input.target.value = [];
        //scope.generateDialog = false;
        scope.setDebugData({
          data,
          reportInfos: {
            lat: this.lat,
            lng: this.lng,
            address: scope.address,
            numberOfPiles: scope.numberOfPiles,
            dates: scope.dates,
            distanceThreshold,
            formula,
          },
        });

        if (data.status < 400) {
          scope.$router.push("/debugger");
        } else {
          scope.$router.replace("/");
        }
      } catch (e) {
      } finally {
        scope.preparingReport = false;
      }
    },
    async fetchRawData() {
      if (this.dates.length < 1) {
        alert('Veuillez sélectioner une date ou un intervalle de dates');
        this.$refs.dates.focus();
        return;
      }
      let startDate = new Date(this.dates[0]).setUTCHours(0, 0, 0, 0);;
      let endDate = new Date(this.dates[this.dates.length - 1]).setUTCHours(23, 59, 59, 999);
      

      let numberOfPiles =
        this.numberOfPiles != "" && this.numberOfPiles !== 0
          ? this.numberOfPiles
          : null;

      let distanceThreshold = this.tuneSet ? this.distanceThreshold : null;
      let formula = this.tuneSet ? this.torqueFormula : null;

      var configToSave = {
        startDate,
        endDate,
        numberOfPiles,
        distanceThreshold,
        address: this.address,
        uuid: this.generateUUID(),
        date: Date.now(),
        tuneSet: this.tuneSet,
        formula,
        lat: this.lat,
        lng: this.lng,
      };

      this.$refs.savedGen.saveToLocalStorage(configToSave);
      this.setRawData({
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString()
      });
      this.$router.push(`/rawTable`);

    },
    async handleReportGeneration() {
      if (this.dates.length < 1) {
        this.$refs.dates.focus();
        return;
      }

      const scope = this;
      this.preparingReport = true;
      let startDate;
      let endDate;

      if (this.dates.length === 0) {
        alert("Veuillez insérer au moins une date.");
        return;
      }

      if (this.dates.length === 1) {
        startDate = new Date(this.dates[0]).setUTCHours(0, 0, 0, 0);
        endDate = new Date(this.dates[0]).setUTCHours(23, 59, 59, 999);
      } else {
        startDate = new Date(this.dates[0]).setUTCHours(0, 0, 0, 0);
        endDate = new Date(this.dates[1]).setUTCHours(23, 59, 59, 999);
      }
      console.log("NUMBER OF PILES", this.numberOfPiles);

      let numberOfPiles =
        this.numberOfPiles != "" && this.numberOfPiles !== 0
          ? this.numberOfPiles
          : null;

      let distanceThreshold = this.tuneSet ? this.distanceThreshold : null;
      let formula = this.tuneSet ? this.torqueFormula : null;

      var configToSave = {
        startDate,
        endDate,
        numberOfPiles,
        distanceThreshold,
        address: this.address,
        uuid: this.generateUUID(),
        date: Date.now(),
        tuneSet: this.tuneSet,
        formula,
        lat: this.lat,
        lng: this.lng,
      };

      this.$refs.savedGen.saveToLocalStorage(configToSave);

      try {
        const data = await this.$axios.post(
          "/generateReport",
          {
            lat: this.lat,
            lng: this.lng,
            address: this.address,
            startDate: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString(), //new Date("2021-09-30 13:26:00.000").toISOString(),
            numberOfPiles,
            distanceThreshold,
            formula,
          },
          {
            timeout: 20000,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        scope.isLoading = false;
        //input.target.value = [];
        //scope.generateDialog = false;

        if (data.status < 400) {
          scope.$emit("onReportGenerated", {
            data,
            reportInfos: {
              address: scope.address,
              numberOfPiles: scope.numberOfPiles,
              dates: scope.dates,
            },
          });
        } else {
          scope.$router.replace("/");
        }
      } catch (e) {
      } finally {
        scope.preparingReport = false;
      }
    },
    async processAlgo() {
      // let data = {
      //   startDate: new Date(new Date(this.dates[0]).setUTCHours(0, 0, 0, 0)).toISOString(),
      //   endDate: new Date(new Date(this.dates[this.dates.length - 1]).setUTCHours(23, 59, 59, 999)).toISOString()
      // };
      let data = {
        startDate: new Date(new Date(this.dates[0]).getTime()).toUTCString(),
        endDate: new Date(new Date(this.dates[this.dates.length - 1]).getTime() + (24 * 3600 * 1000 - 1)).toUTCString(),
        machineId: this.selectedMachine.value
      };
      if (this.lat && this.lng && this.distanceThreshold && this.addressSelected && this.address && this.address != '') data.limits = {
        lat: this.lat,
        lng: this.lng, 
        radius: this.distanceThreshold,
        address: this.address
      };
      try {
        this.isLoading = false;
        const result = await this.$axios.post("/api/execAlgo", data,
          {
            timeout: 300000,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (result.data.message) {
          //clearTimeout(this.messageTimer);
          this.message = result.data.message;
          this.snackbar = true;
          //let scope = this;
          return;// this.messageTimer = setTimeout(() => scope.message = null, 3000);
        }

        for (let data of result.data) {
          let file = new Blob([data.base64 == true ? Buffer.from(data.content, 'base64') : data.content], {type: data.mimeType || 'text/csv'});
          let url = URL.createObjectURL(file);
          let a = document.createElement('a');
          a.href = url;
          a.download = data.filename;
          const clickHandler = () => {
            setTimeout(() => {
              URL.revokeObjectURL(url);
              a.remove();
            }, 100);
          };
          a.addEventListener('click', clickHandler, false);
          a.click();

          if (data.maps) {
            a = document.createElement('a');
            a.href = result.data.maps;
            a.target = '_blank';
            a.addEventListener('click', a.remove, false);
            a.click();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    handleSelected(item) {
      // console.log("SELECT", item);
      this.address = item.address;
      this.lat = item.lat;
      this.lng = item.lng;
      const prettyStart = new Date(item.startDate).toISOString().split("T")[0];
      const prettyEnd = new Date(item.endDate).toISOString().split("T")[0];

      if (prettyStart === prettyEnd) {
        this.dates = [prettyStart];
      } else {
        this.dates = [prettyStart, prettyEnd];
      }

      this.numberOfPiles = item.numberOfPiles;
      this.distanceThreshold = parseInt(item.distanceThreshold);
      this.formula = item.formula
        ? item.formula
        : "(torque_dec - 16384) * 4.25522";
      this.tuneSet = item.tuneSet;

      this.$emit("onAddressSelected", {
        lat: item.lat,
        lng: item.lng,
        distanceThreshold: parseInt(item.distanceThreshold),
      });
      //this.$refs.input.focus();
    },
    generateUUID() {
      // Public Domain/MIT
      var d = new Date().getTime(); //Timestamp
      var d2 =
        (typeof performance !== "undefined" &&
          performance.now &&
          performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = Math.random() * 16; //random number between 0 and 16
          if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
    },
  },
};
</script>
<style lang="scss">
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat";
}

.no-transform {
  text-transform: none;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.modal-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.info {
  color: #999;
  font-size: 0.75em;
}

</style>
