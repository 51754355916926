import axios from "axios";

/**
 * Create axios instance.
 */
const $axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL || "http://192.168.1.66:3000",
});

export default $axios;
