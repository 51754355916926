<template>
  <v-btn
    absolute
    fab
    :color="color"
    dark
    :style="{ 'z-index': 9999, left: left, top: top, right: right }"
    :loading="loading"
    @click="onClick"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    /**
     * Si le bouton est disabled et affiche un spinner.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Icône à afficher.
     */
    icon: {
      type: String,
      default: "mdi-refresh",
    },
    /**
     * Couleur de background du bouton.
     */
    color: {
      type: String,
      default: "#131E30",
    },
    /**
     * Valeur de distance absolue gauche.
     */
    left: {
      type: String,
      default: "",
    },
    /**
     * Valeur de distance absolue droite.
     */
    right: {
      type: String,
      default: "",
    },
    /**
     * Valeur de distance absolue du haut.
     */
    top: {
      type: String,
      default: "15px",
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      /**
       * Lorsqu'on appuie sur le bouton.
       * Évènement onClick
       */
      this.$emit("onClick");
    },
  },
};
</script>
<style></style>
