<template>
  <apexchart
    v-if="!isLoading"
    height="900px"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

import { smoothOut } from "../../utils";

export default {
  name: "TorqueVsProfChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    /**
     * Données du graphique
     */
    series: [
      {
        name: "Torque vs Prof",
        data: [],
      },
      {
        name: "Torque vs Prof Avg.",
        data: [],
      },
    ],
    /**
     * Options du graphique
     * https://apexcharts.com/docs/chart-types/line-chart/
     */
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        type: "line",
        stacked: false,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 1,
        dashArray: 0,
      },
      markers: {
        size: 0,
        colors: ["transparent"],
        strokeWidth: 0,
      },
      title: {
        text: "",
        align: "left",
      },
      yaxis: {
        lines: {
          show: true,
        },
        tickAmount: 12,
        min: -215, // start date
        max: 0,
        title: {
          text: "Profondeur en cm",
        },
        tooltip: {
          enabled: true,
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
      },
      xaxis: {
        marker: {
          show: false,
        },
        tooltip: {
          enabled: true,
          marker: {
            show: false,
          },
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
        lines: {
          show: true,
        },
        tickAmount: 8,
        min: 0,
        title: {
          text: "Couple en lbs.pieds",
        },
      },
    },
  }),
  mounted() {
    const scope = this;
    this.isLoading = true;

    const seriesData = [...this.items];

    seriesData.forEach((item) => {
      item.deltaZ = item.deltaZ * -1;
    });

    seriesData.sort((a, b) => {
      return a.deltaZ - b.deltaZ;
    });

    const testArrTorq = [];
    const testArrDeltaZ = [];

    seriesData.forEach((item) => {
      testArrTorq.push(parseInt(item.Torque));
      testArrDeltaZ.push(item.deltaZ);
    });

    this.series[0].data = seriesData.map((item, index) => {
      return [parseInt(item.Torque), item.deltaZ];
    });

    this.series[1].data = [];
    let testArraySmooth = smoothOut(testArrTorq, 1.33);
    testArraySmooth.forEach((element, index) => {
      this.series[1].data.push([element, testArrDeltaZ[index]]);
    });

    this.isLoading = false;
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
::v-deep .apexcharts-tooltip,
.apexcharts-xcrosshairs {
  display: none !important;
}
</style>
